import {FC, useContext} from 'react';
import ListItemGroup, {isEvent} from "./ListItemGroup";
import styles from "./styles/SchoolFeedList.module.scss";
import {
    IReactiveCourseWorkContentItem,
    IReactiveEventContentItem,
    IReactiveNewsContentItem,
} from "../../stores/SchoolFeedStore";
import {SocialItemModalNavigationProps} from "../modals/SocialItemModal";
import classNames from "classnames";
import {StoreContext} from "../../stores/StoreLoader";

const SchoolFeedList: FC<{
    content: Array<IReactiveEventContentItem | IReactiveNewsContentItem | IReactiveCourseWorkContentItem>,
    allowCuration: boolean,
    showMedia: boolean,
    handleDelete: (c: ContentItem) => void,
    className?: string,
} & Omit<SocialItemModalNavigationProps, "initialItemIndex">> = props => {
    const {organizationStore} = useContext(StoreContext);

    const schoolFeedListClassName = classNames({
        [styles.container]: true,
        [props.className || '']: props.className,
        [styles.containerFloatingBlocks]: organizationStore.organization.json_data?.settings?.appearance?.themeID === "2",
    })

    return <div className={schoolFeedListClassName}>
        <ul>
            {props.content.reduce((acc: Array<Array< IReactiveEventContentItem | IReactiveNewsContentItem | IReactiveCourseWorkContentItem>>, curr) => {
                if (acc.length === 0) {
                    // this is the first item, so just push the current item into the list
                    acc.push([curr]);
                } else {
                    const lastGroupAdded = acc[acc.length - 1];
                    const lastItemAdded = lastGroupAdded[lastGroupAdded.length - 1];
                    let shouldAddToLastGroup = false;

                    if (isEvent(curr) && (
                        !lastItemAdded.is_event || (
                            isEvent(lastItemAdded) && lastItemAdded.json_data.event.start !== curr.json_data.event.start
                        )
                    )) {
                        /* First and foremost, we check dates. If current item is a date, it should ALWAYS start a new group
                        UNLESS it is the same date as the previous item. */
                        shouldAddToLastGroup = false;
                    } else if (curr.organization?.id === lastItemAdded.organization?.id) {
                        /* If current item is from the same organization as the previous item, we will consider grouping them
                        based on images */

                        const currentItemImage = curr.image;
                        const lastItemAddedImage = lastItemAdded.image;

                        if (currentItemImage === lastItemAddedImage && currentItemImage !== null) {
                            // If current image matches last image, group them. Or if current item doesn't have an image, group them.
                            shouldAddToLastGroup = true;
                        }

                    } else {
                        // Otherwise, we start a new group.
                        shouldAddToLastGroup = false;
                    }

                    shouldAddToLastGroup === true ? lastGroupAdded.push(curr) : acc.push([curr]);
                }
                return acc;
            }, []).map((arrayOfItems, idx) => { // this is an array of item objects that all have the same image or lack thereof but have the same source

                return <li key={arrayOfItems[0].id}>
                    <ListItemGroup
                        showMedia={props.showMedia}
                        allowCuration={props.allowCuration}
                        items={arrayOfItems}
                        handleDelete={props.handleDelete}
                        getItemAtIndex={props.getItemAtIndex}
                        totalItemCount={props.totalItemCount}
                        firstItemIndex={idx}
                    />
                    <hr/>
                </li>
            })}
        </ul>
    </div>
}

export default SchoolFeedList;
